import { Theme } from '@carbon/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './Loader.scss';

import AppLogo from 'assets/v3/app_logo_small.png';
import HorizontalLogo from 'assets/v3/horizontal_logo.svg';

export const Loader = () => {
  const { t } = useTranslation();

  return (
    <Theme theme='g90' className='loader'>
      <div className='loader__animate-ping'></div>
      <div className='loader__logo'>
        <img src={AppLogo} alt={t('Logo')} className='logo-image' />
        <img
          className='loader__horizontal-logo'
          src={HorizontalLogo}
          alt={t('SkyDeck.ai GenStudio')}
        />
      </div>
    </Theme>
  );
};

export default Loader;
