import { PropTypes } from 'prop-types';
import React, { createContext } from 'react';
import useWebSocket from 'react-use-websocket';

import { WS_CHAT } from 'constants/constants';
import { buildUrl } from 'utils/utils';

const MAX_RETRY_TIMES = 6 * 30; // 30 minutes
const MAX_RECONNECT_INTERVAL = 10000;

export const WebSocketContext = createContext();

export const WebSocketProvider = ({ children }) => {
  let url = buildUrl(WS_CHAT).replace('http', 'ws');

  const webSocket = useWebSocket(url, {
    shouldReconnect: () => true,
    reconnectAttempts: MAX_RETRY_TIMES,
    reconnectInterval: (attemptNumber) =>
      Math.min(Math.pow(2, attemptNumber) * 1000, MAX_RECONNECT_INTERVAL),
    retryOnError: true,
  });
  return <WebSocketContext.Provider value={webSocket}>{children}</WebSocketContext.Provider>;
};

WebSocketProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
