const prepareLatexForRendering = (content) => {
  if (content === undefined || content === null) {
    return '';
  }

  const blocks = content.split(/(```[\s\S]*?```|``[\s\S]*?``)/g);

  const processedBlocks = blocks.map((block) => {
    if (block.startsWith('```') || block.startsWith('``')) {
      return block;
    }

    let processedBlock = block;

    processedBlock = processedBlock.replace(/\\\[([\s\S]*?)\\\]/g, (match, p1) => {
      return `\n$$\n${p1.trim()}\n$$\n`;
    });

    processedBlock = processedBlock.replace(/\\\(([\s\S]*?)\\\)/g, (match, p1) => {
      return `$${p1.trim()}$`;
    });

    return processedBlock;
  });

  return processedBlocks.join('');
};

const escapeNonLatexDollarsInBlock = (block) => {
  if (!block) {
    return block;
  }

  const latexPattern = /(?:\\[a-zA-Z]+|[!@&%$#_{}\^~\\])/g;

  const lines = block.split('\n');

  const processedLines = lines.map((line) => {
    if (line.includes('\\$')) {
      return line;
    }

    const result = new Array(line.split('$').length + 2);
    let resultIndex = 0;
    let i = 0;
    let prev_i = 0;

    while (i < line.length) {
      prev_i = i;
      i = line.indexOf('$', i);

      if (i === -1) {
        result[resultIndex] = line.substring(prev_i, line.length);
        i = line.length;
      } else {
        if (prev_i != i) {
          result[resultIndex++] = line.substring(prev_i, i);
        }

        let closingIndex = line.indexOf('$', i + 1);
        if (closingIndex === -1) {
          result[resultIndex] = '\\$' + line.substring(i + 1, line.length);
          i = line.length;
        } else {
          let between_str = line.substring(i + 1, closingIndex);
          if (between_str.match(latexPattern)) {
            result[resultIndex++] = `$${between_str}$`;
            i = closingIndex + 1;
          } else {
            result[resultIndex++] = `\\$${between_str}`;
            i = closingIndex;
          }
        }
      }
    }

    return result.join('');
  });

  return processedLines.join('\n');
};

const escapeDollarsInNonLatexLines = (content) => {
  if (!content) {
    return content;
  }

  const blockPattern =
    /(?:```[\s\S]*?```|```[\s\S]*$|`[\s\S]*?`|\$\$[\s\S]*?\$\$|\\\[[\s\S]*?\\\]|\\\([\s\S]*?\\\))/g;

  const matchingBlocks = content.match(blockPattern) || [];

  const nonMatchingBlocks = content.split(blockPattern);

  const resultArray = [];

  for (let i = 0; i < nonMatchingBlocks.length; i++) {
    resultArray.push(escapeNonLatexDollarsInBlock(nonMatchingBlocks[i]));
    if (i < matchingBlocks.length) {
      resultArray.push(matchingBlocks[i]);
    }
  }

  return resultArray.join('');
};

const prepareLatexContent = (content) => {
  return prepareLatexForRendering(escapeDollarsInNonLatexLines(content));
};

export { prepareLatexContent };
