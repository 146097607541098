import { Attachment, Document } from '@carbon/icons-react';
import { Modal } from '@carbon/react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getAbsoluteURL } from 'utils/utils';

import './UploadedFileNameList.scss';
import PopoverWrapper from './PopoverWrapper';

const DocumentItem = ({ file_name, url }) => {
  const handleClick = () => {
    window.open(url, '_blank');
  };

  return (
    <PopoverWrapper content={<div className='file-name__tooltip'>{file_name}</div>} align='bottom'>
      <div onClick={handleClick} className='file-name-item'>
        <Document size={16} className='file-icon' />
        <span className='file-name'>{file_name}</span>
      </div>
    </PopoverWrapper>
  );
};

DocumentItem.propTypes = {
  file_name: PropTypes.string.isRequired,
  url: PropTypes.string,
};

export const UploadedFileNameList = ({ uploadedFiles = {}, showLabel = true }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const images = uploadedFiles?.images || [];
  const documents = uploadedFiles?.documents || [];

  if (isEmpty(images) && isEmpty(documents)) {
    return null;
  }

  const openModal = (file) => {
    setPreviewImage(file);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setPreviewImage(null);
  };

  return (
    <div id='uploaded-file-name-list'>
      {showLabel && (
        <div className='uploaded-label'>
          <Attachment size={16} />
          <p className='label-name'>{t('Sources')}</p>
        </div>
      )}
      <div className='file-image-container'>
        {images.map((file, index) => (
          <div key={index} className='image-preview-container' onClick={() => openModal(file)}>
            <img src={getAbsoluteURL(file.url)} alt={file.file_name} className='file-preview' />
          </div>
        ))}
      </div>
      <div className='file-list-container'>
        {documents.map((file, index) => (
          <DocumentItem key={index} file_name={file.file_name} url={getAbsoluteURL(file.url)} />
        ))}
      </div>

      <Modal
        open={isModalOpen}
        onRequestClose={closeModal}
        modalHeading='Image Preview'
        autoFocus={false}
        passiveModal>
        {previewImage && (
          <img
            src={getAbsoluteURL(previewImage.url)}
            alt={previewImage.name}
            style={{ maxWidth: '100%', maxHeight: '70vh' }}
          />
        )}
      </Modal>
    </div>
  );
};

UploadedFileNameList.propTypes = {
  uploadedFiles: PropTypes.object,
  showLabel: PropTypes.bool,
};
