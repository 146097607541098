import { Content, Button, Theme } from '@carbon/react';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import ContinueSnapshotModal from 'components_v3/ContinueSnapshotModal';
import { ConversationMessage } from 'components_v3/ConversationMessage';
import Loader from 'components_v3/Loader';
import Navbar from 'components_v3/Navbar';
import { NotFound } from 'components_v3/NotFound';
import { CONVERSATION_NAME_MAX_LENGTH } from 'constants/constants';
import { CONTINUE_SNAPSHOT_PATH } from 'constants/routes';
import { usePublicSnapshot, useSnapshotSignupMutation } from 'hooks_v3/snapshots';
import { displaySnapshotTime } from 'utils/snapshot';

import 'pages_v3/PublicSnapshot.scss';
import 'components_v3/Conversation.scss';

const PublicSnapshot = () => {
  const { snapshot_uuid } = useParams();
  const { data, error, isLoading } = usePublicSnapshot(snapshot_uuid);
  const { t, i18n } = useTranslation();
  const snapshotSignupMutation = useSnapshotSignupMutation();
  const [openContinueModal, setOpenContinueModal] = useState(false);

  const setDocumentTitle = (name = '') => {
    const maxLength = CONVERSATION_NAME_MAX_LENGTH;
    const suffix = ' - SkyDeck.ai GenStudio';
    if (name.length > maxLength - 6 && name.length <= maxLength) {
      document.title = name;
      return;
    }

    const fullTitle = `${name}${suffix}`;
    const truncate = (str, len) => (str.length > len ? `${str.slice(0, len - 3)}...` : str);
    const truncatedTitle = truncate(fullTitle, maxLength);
    document.title = truncatedTitle;
  };

  useEffect(() => {
    const conversationName = data?.conversation_data?.name || '';
    setDocumentTitle(conversationName);
  }, [data]);

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return <NotFound />;
  }

  const { name, created, messages, llms } = data.conversation_data;

  const checkWorkspaceURL = async (workspaceURL) => {
    const url = `${window.location.protocol}//${workspaceURL}`;
    try {
      await fetch(url, { mode: 'no-cors', signal: AbortSignal.timeout(10000) });
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleCopyConversation = async (workspaceUrl) => {
    const checked = await checkWorkspaceURL(workspaceUrl);
    if (checked) {
      const newUrl = `${window.location.protocol}//${workspaceUrl}${CONTINUE_SNAPSHOT_PATH.replace(
        ':snapshot_uuid',
        snapshot_uuid,
      )}`;

      window.location.href = newUrl;
    } else {
      toast.error(t('We cannot find your workspace. Please try again.'));
    }
  };

  const handleSnapshotSignup = async (data) => {
    const res = await snapshotSignupMutation.mutateAsync(data);
    if (res.error) {
      if (res.error?.user_email) {
        toast.error(t('A user with this email already exists.'));
      }

      if (res.error?.snapshot) {
        toast.error(t('This snapshot has been removed by its owner.'));
      }

      return;
    }

    window.location.href = `${import.meta.env.VITE_BASE_URL}/sign-up/?email=${encodeURIComponent(
      data.user_email,
    )}`;
  };

  const handleRequestSubmit = async (data) => {
    if (data.email !== '') {
      return handleSnapshotSignup({
        user_email: data.email,
        snapshot: snapshot_uuid,
      });
    }

    if (data.workspaceURL) {
      return handleCopyConversation(data.workspaceURL);
    }
  };

  return (
    <>
      <Navbar
        options={{ showCompanyLogo: false, showDocs: true, showAccount: false, showSideNav: false }}
      />
      <Content id='main-content' className='snapshot-page-main-content'>
        <div id='home'>
          <div id='conversation'>
            <div id='conversation-chat' className='conversation'>
              <Theme theme='g90'>
                <ul id='messages-container' className='scrollbar-thin'>
                  <li
                    className='message assistant-message snapshot-title'
                    data-cy='conversation-message'>
                    <div className='message-wrapper'>
                      <div className='content'>
                        <p className='title-name'>{name}</p>
                        <p className='title-created'>
                          {created ? displaySnapshotTime(created, i18n.language) : ''}
                        </p>
                      </div>
                    </div>
                  </li>
                  {messages.map((message) => (
                    <ConversationMessage
                      key={message.id}
                      message={message}
                      streamingResponse=''
                      isError={false}
                      isConversationOwner={false}
                      isDeletionDisabled={true}
                      isShown={true}
                      snapshot_llms={llms}
                      inSnapshot={true}
                    />
                  ))}
                  <li className='message snapshot-action' data-cy='conversation-message'>
                    <div className='message-wrapper'>
                      <div className='content'>
                        <div className='continue-button-wrapper'>
                          <Button kind='primary' onClick={() => setOpenContinueModal(true)}>
                            {t('Continue this conversation')}
                          </Button>
                        </div>

                        {typeof document !== 'undefined' &&
                          createPortal(
                            <ContinueSnapshotModal
                              open={openContinueModal}
                              setOpen={setOpenContinueModal}
                              onRequestSubmit={handleRequestSubmit}
                            />,
                            document.body,
                          )}
                      </div>
                    </div>
                  </li>
                </ul>
              </Theme>
            </div>
          </div>
        </div>
      </Content>
    </>
  );
};

export default PublicSnapshot;
