import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useContext } from 'react';

import { API_PROFILE, API_SUBDOMAIN_INFOMATION } from 'constants/constants';
import { ApiClientContext } from 'context/api-client';
import { buildUrl } from 'utils/utils';

const accountKeys = {
  profile: ['profile'],
  subdomainInfo: (subdomain) => ['subdomainInfo', subdomain],
};

export const useProfileQuery = (options = {}) => {
  const apiClient = useContext(ApiClientContext);

  return useQuery({
    queryKey: accountKeys.profile,
    queryFn: async () => {
      const res = await apiClient(buildUrl(API_PROFILE));
      if (!res.ok) throw Error('Load failed');
      return res.json();
    },
    select: (data) => {
      if (data.data && data.data.account && data.data.account.logo) {
        const transformedData = {
          ...data,
          data: {
            ...data.data,
            account: {
              ...data.data.account,
              logo: data.data.account.logo.split('?')[0],
            },
          },
        };
        return transformedData;
      }
      return data;
    },
    ...options,
  });
};

export const usePatchProfile = () => {
  const apiClient = useContext(ApiClientContext);
  const queryClient = useQueryClient();

  return useMutation(
    async (data) => {
      const res = await apiClient(buildUrl(API_PROFILE), {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      if (!res?.ok) throw Error('Load failed');
      return res.json();
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries(accountKeys.profile);
      },
    },
  );
};

export const useSubdomainInfo = (subdomain) => {
  const apiClient = useContext(ApiClientContext);

  return useQuery({
    retry: 0,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    queryKey: accountKeys.subdomainInfo(subdomain),
    queryFn: async () => {
      const res = await apiClient(
        buildUrl(API_SUBDOMAIN_INFOMATION, [
          {
            name: 'subdomain',
            value: subdomain,
          },
        ]),
      );
      if (!res.ok) throw Error('Load failed');
      return res.json();
    },
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 24 * 60 * 60 * 1000,
  });
};
